/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import styled from "styled-components";
import GitHubButton from "react-github-btn";

const GitHubDivContent = styled.div`
  width: auto;
  padding: 0;
  float: right;
  margin-right: 2px;
  margin-left: 12px;
  display: ${p => (p.showGithubLink ? "display-inline" : "none")};
`;

const GithubLinkProps = {
  repository: "https://github.com/apache/atlas/"
};

export const GithubLink = ({ repository, ...props }) => {
  return (
    <GitHubDivContent {...props}>
      <GitHubButton
        href="https://github.com/apache/atlas/fork"
        data-size="large"
        aria-label="Fork apache/atlas on GitHub"
      >
        Fork
      </GitHubButton>

      <GitHubButton
        href="https://github.com/apache/atlas/archive/master.zip"
        data-size="large"
        aria-label="Download apache/atlas on GitHub"
      >
        Download
      </GitHubButton>

      <GitHubButton
        href="https://github.com/apache/atlas"
        data-size="large"
        aria-label="Star apache/atlas on GitHub"
      >
        Star
      </GitHubButton>
    </GitHubDivContent>
  );
};