/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const white = "#FFFFFF";
export const grayUltraLight = "#FCFBFA";
export const grayExtraLight = "#F5F6F7";
export const grayLight = "#CED4DE";
export const gray = "#7D899C";
export const grayDark = "#2D3747";
export const grayExtraDark = "#1D2330";
export const dark = "#13161F";

export const blueLight = "#e9f2fa";
export const blue = "#4a90e2 ";
export const skyBlue = "#1FB6FF";
export const negative = "#EB4D4B";

export const green = "#37bb9b";
export const greenLight = "#529d8b";
