/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import { SFC } from "react";
import styled from "styled-components";
import SearchIcon from "react-feather/dist/icons/search";

import { get } from "../../../utils/theme";

const sidebarBorder = get("colors.sidebarBorder", "#CED4DE");
const sidebarText = get("colors.sidebarText", "#13161F");

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 24px;
  margin-bottom: 20px;
  border-top: 1px dotted ${sidebarBorder};
  border-bottom: 1px dotted ${sidebarBorder};
  opacity: 1;
`;

const Icon = styled(SearchIcon)`
  stroke: ${sidebarText};
  min-width: 20px;
  opacity: 0.5;
`;

const Input = styled.input`
  outline: none;
  width: 100%;
  padding: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  color: ${sidebarText};
`;

const SearchProps = {
  onSearch: value => {}
};

export const Search = ({ onSearch }) => (
  <Wrapper>
    <Icon />
    <Input
      type="text"
      placeholder="Search here..."
      onChange={ev => {
        onSearch && onSearch(ev.target.value);
      }}
    />
  </Wrapper>
);
Search.defaultProps = SearchProps;