/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import { SFC } from "react";
import { Entry, useDocs, useConfig } from "../../../../docz-lib/docz/dist";
import { Location } from "@reach/router";
import styled from "styled-components";
import get from "lodash/get";

import { SmallLink } from "./SmallLink";

const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 24px;
`;

const getHeadings = (route, docs) => {
  const doc = docs.find(doc => doc.route === route);
  const headings = get(doc, "headings");
  return headings ? headings.filter(heading => heading.depth === 2) : [];
};

const MenuHeadingsProps = {
  route: "",
  onClick: null
};

export const MenuHeadings = ({ route, onClick }) => {
  const docs = useDocs();
  const { linkComponent: Link } = useConfig();
  const headings = docs && getHeadings(route, docs);

  return null;
};
