export const imports = {
  'documents/BusinessMetadata.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-business-metadata" */ 'documents/BusinessMetadata.md'
    ),
  'documents/ClassificationPropagation.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-classification-propagation" */ 'documents/ClassificationPropagation.md'
    ),
  'documents/Glossary.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-glossary" */ 'documents/Glossary.md'
    ),
  'documents/HighAvailability.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-high-availability" */ 'documents/HighAvailability.md'
    ),
  'documents/HighLevelArchitecture.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-high-level-architecture" */ 'documents/HighLevelArchitecture.md'
    ),
  'documents/Notifications.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-notifications" */ 'documents/Notifications.md'
    ),
  'documents/Overview.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-overview" */ 'documents/Overview.md'
    ),
  'documents/RestAPI.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-rest-api" */ 'documents/RestAPI.md'
    ),
  'documents/TypeSystem.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-type-system" */ 'documents/TypeSystem.md'
    ),
  'documents/Asf/asfinfo.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-asf-asfinfo" */ 'documents/Asf/asfinfo.md'
    ),
  'documents/Downloads/Downloads.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-downloads-downloads" */ 'documents/Downloads/Downloads.md'
    ),
  'documents/Hook/HookFalcon.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-hook-hook-falcon" */ 'documents/Hook/HookFalcon.md'
    ),
  'documents/Hook/HookHBase.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-hook-hook-h-base" */ 'documents/Hook/HookHBase.md'
    ),
  'documents/Hook/HookHive.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-hook-hook-hive" */ 'documents/Hook/HookHive.md'
    ),
  'documents/Hook/HookKafka.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-hook-hook-kafka" */ 'documents/Hook/HookKafka.md'
    ),
  'documents/Hook/HookSqoop.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-hook-hook-sqoop" */ 'documents/Hook/HookSqoop.md'
    ),
  'documents/Hook/HookStorm.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-hook-hook-storm" */ 'documents/Hook/HookStorm.md'
    ),
  'documents/Import-Export/ExportAPI.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-export-api" */ 'documents/Import-Export/ExportAPI.md'
    ),
  'documents/Import-Export/ExportHDFSAPI.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-export-hdfsapi" */ 'documents/Import-Export/ExportHDFSAPI.md'
    ),
  'documents/Import-Export/ExportImportAudits.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-export-import-audits" */ 'documents/Import-Export/ExportImportAudits.md'
    ),
  'documents/Import-Export/ImportAPI.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-import-api" */ 'documents/Import-Export/ImportAPI.md'
    ),
  'documents/Import-Export/ImportAPIOptions.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-import-api-options" */ 'documents/Import-Export/ImportAPIOptions.md'
    ),
  'documents/Import-Export/ImportEntityTransforms.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-import-entity-transforms" */ 'documents/Import-Export/ImportEntityTransforms.md'
    ),
  'documents/Import-Export/ImportExportAPI.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-import-export-api" */ 'documents/Import-Export/ImportExportAPI.md'
    ),
  'documents/Import-Export/IncrementalExport.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-import-export-incremental-export" */ 'documents/Import-Export/IncrementalExport.md'
    ),
  'documents/Migration/Migration-0.8-to-1.0.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-migration-migration-0-8-to-1-0" */ 'documents/Migration/Migration-0.8-to-1.0.md'
    ),
  'documents/Misc/AtlasServer.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-misc-atlas-server" */ 'documents/Misc/AtlasServer.md'
    ),
  'documents/Misc/ReplicatedAttributes.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-misc-replicated-attributes" */ 'documents/Misc/ReplicatedAttributes.md'
    ),
  'documents/Misc/SoftReference.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-misc-soft-reference" */ 'documents/Misc/SoftReference.md'
    ),
  'documents/Project-Info/IssueTracking.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-project-info-issue-tracking" */ 'documents/Project-Info/IssueTracking.md'
    ),
  'documents/Project-Info/MailingLists.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-project-info-mailing-lists" */ 'documents/Project-Info/MailingLists.md'
    ),
  'documents/Project-Info/ProjectInfo.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-project-info-project-info" */ 'documents/Project-Info/ProjectInfo.md'
    ),
  'documents/Project-Info/ProjectLicense.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-project-info-project-license" */ 'documents/Project-Info/ProjectLicense.md'
    ),
  'documents/Project-Info/SourceRepository.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-project-info-source-repository" */ 'documents/Project-Info/SourceRepository.md'
    ),
  'documents/Project-Info/TeamList.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-project-info-team-list" */ 'documents/Project-Info/TeamList.md'
    ),
  'documents/Search/SearchAdvanced.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-search-search-advanced" */ 'documents/Search/SearchAdvanced.md'
    ),
  'documents/Search/SearchBasic.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-search-search-basic" */ 'documents/Search/SearchBasic.md'
    ),
  'documents/Search/SearchRelationship.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-search-search-relationship" */ 'documents/Search/SearchRelationship.md'
    ),
  'documents/Security/AtlasRangerAuthorizer.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-security-atlas-ranger-authorizer" */ 'documents/Security/AtlasRangerAuthorizer.md'
    ),
  'documents/Security/AtlasSimpleAuthorizer.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-security-atlas-simple-authorizer" */ 'documents/Security/AtlasSimpleAuthorizer.md'
    ),
  'documents/Security/Authentication.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-security-authentication" */ 'documents/Security/Authentication.md'
    ),
  'documents/Security/AuthorizationModel.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-security-authorization-model" */ 'documents/Security/AuthorizationModel.md'
    ),
  'documents/Security/Security.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-security-security" */ 'documents/Security/Security.md'
    ),
  'documents/Setup/BuildInstruction.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-setup-build-instruction" */ 'documents/Setup/BuildInstruction.md'
    ),
  'documents/Setup/Configuration.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-setup-configuration" */ 'documents/Setup/Configuration.md'
    ),
  'documents/Setup/EclipseSetup.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-setup-eclipse-setup" */ 'documents/Setup/EclipseSetup.md'
    ),
  'documents/Setup/InstallationInstruction.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-setup-installation-instruction" */ 'documents/Setup/InstallationInstruction.md'
    ),
  'documents/Setup/QuickStart.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-setup-quick-start" */ 'documents/Setup/QuickStart.md'
    ),
  'documents/Tools/AtlasRepairIndex.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-tools-atlas-repair-index" */ 'documents/Tools/AtlasRepairIndex.md'
    ),
  'documents/Whats-New/WhatsNew-1.0.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-whats-new-whats-new-1-0" */ 'documents/Whats-New/WhatsNew-1.0.md'
    ),
  'documents/Whats-New/WhatsNew-2.0.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-whats-new-whats-new-2-0" */ 'documents/Whats-New/WhatsNew-2.0.md'
    ),
  'documents/Whats-New/WhatsNew-2.1.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-whats-new-whats-new-2-1" */ 'documents/Whats-New/WhatsNew-2.1.md'
    ),
  'documents/Whats-New/WhatsNew-2.2.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-whats-new-whats-new-2-2" */ 'documents/Whats-New/WhatsNew-2.2.md'
    ),
  'documents/Whats-New/WhatsNew-2.3.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "documents-whats-new-whats-new-2-3" */ 'documents/Whats-New/WhatsNew-2.3.md'
    ),
}
